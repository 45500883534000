<template>
  <div class="d-flex w-100 h-100">
    <div class="he-ui-nurse-img">
      <img class="w-100 h-100" src="../../assets/sanitaria.svg" alt="nurse" />
    </div>
    <div class="he-ui-calendar-wrapper h-100">
      <Calendar
        :onClickDay="onClickDay"
        :onMonthChange="monthChange" />
    </div>
  </div>
</template>

<script>
import Calendar from '@/components/Calendar'

export default {
  components: {
    Calendar
  },
  methods: {
    monthChange (dateSelected) {
      console.log(dateSelected)
    },
    onClickDay (dateSelected) {
      console.log(dateSelected)
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-nurse-img {
  width: 60%;
  margin-right: 40px;
}
.he-ui-calendar-wrapper {
  width: 40%;
}
</style>
