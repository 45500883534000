<template>
  <div class="he-ui-calendar bg-white h-100">
    <SelectorDate
      :editMonth="true"
      :onDateChange="onDateChange" />
    <div
      class="he-ui-calendar-container d-flex flex-column">
      <div class="he-ui-calendar-content-days d-flex mb-4">
          <div
            v-for="day in daysName"
            :key="day"
            class="he-ui-calendar-content-day-name">
            {{ day }}
          </div>
        </div>
      <div class="he-ui-calendar-content">
        <div
          v-for="(monthDay, monthIndex) in monthDaysFiltered"
          :key="monthIndex + '_day'"
          class="he-ui-calendar-content-empty-day" />
        <div
          v-for="(monthDay, monthIndex) in monthDays"
          :key="monthIndex"
          :class="[
            'he-ui-calendar-content-day',
            'he-ui-calendar-content-day-' + monthDay.dayOfWeek,
            {'he-ui-calendar-content-day-current': isCurrentDateOnCalendar(monthDay.dayOfMonth)},
          ]"
          @click.prevent="onHandlerDay(monthDay.dayOfMonth)">
          <div
            v-if="monthDay.dayOfMonth === dayOfMonthSelected"
            :class="[{'he-ui-calendar-content-day-selected': (monthDay.dayOfMonth === dayOfMonthSelected)}]" />
          <div
            class="he-ui-calendar-content-day-number"
            :data-test="`calendar-day-number-${monthDay.dayOfMonth}`">
            {{ monthDay.dayOfMonth }}
          </div>
        </div>
      </div>
    </div>
    <div class="he-ui-calendar-legend d-flex justify-content-between bg-white">
        <div
          v-for="item in legend"
          :key="item.text"
          class="he-ui-calendar-legend-item d-flex align-items-center">
          <div
            :class="item.class"
            class="he-ui-calendar-legend-ball" />
          <span class="he-ui-calendar-legend-text text-uppercase">{{ item.text }}</span>
        </div>
      </div>
  </div>
</template>

<script>
import { getDate, getMonth, getYear } from 'date-fns'
import SelectorDate from '@/components/SelectorDate'

export default {
  components: {
    SelectorDate
  },
  props: {
    events: {
      type: Array
    },
    onMonthChange: {
      type: Function
    },
    onClickDay: {
      type: Function
    }
  },
  data () {
    return {
      calendarMonth: getMonth(new Date()) + 1,
      calendarYear: getYear(new Date()),
      dayOfMonthSelected: new Date(),
      numberDayOfMonth: -1,
      legend: [
        { text: this.$t('main.not_available'), class: 'danger' },
        { text: this.$t('main.available'), class: 'succes' }
      ]
    }
  },
  computed: {
    monthDays () {
      return this.getDaysArray(this.calendarYear, this.calendarMonth)
    },
    calendarDate () {
      return getDate(new Date(getYear(new Date()), getMonth(new Date()), 1))
    },
    monthDaysWeekly () {
      return this.monthDays.filter(day => day.firstDayOfWeek || day.dayOfMonth === 1)
    },
    monthDaysFiltered () {
      return this.monthDays.filter((day, index) => index < this.numberDayOfMonth)
    },
    monthBaseZeroIndex () {
      return this.calendarMonth - 1
    },
    daysName () {
      return ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
    },
    currentDate () {
      return new Date(getYear(new Date()), getMonth(new Date()), getDate(new Date()))
    }
  },
  mounted () {
    // this.onClickDay(dates.getTodayUTCBegin({ format: dates.ISO_DATETIME_FORMAT }))
  },
  methods: {
    getFirstDayOfMonth (day) {
      const days = {
        mon: 0,
        tue: 1,
        wed: 2,
        thu: 3,
        fri: 4,
        sat: 5,
        sun: 6
      }
      this.numberDayOfMonth = days[day]
    },
    daysInMonth (year, month) {
      return new Date(year, month, 0).getDate()
    },
    getCalendarDaysForMonth (year, month) {
      return Array.from({ length: this.daysInMonth(year, month) }, (_, k) => k + 1)
        .map(day => {
          const m = new Date(year, month, day)
          return {
            dayOfMonth: day,
            dayOfWeek: this.daysName[m.getDay()],
            firstDayOfWeek: m.getDay() === 1
          }
        })
    },
    getDaysArray (year, month) {
      const days = this.getCalendarDaysForMonth(year, month - 1)
      this.getFirstDayOfMonth(days[0].dayOfWeek)
      return days
    },
    onHandlerDay (dayOfMonth) {
      const dateSelected = 0// dates.getFactoryUTCDate({ year: this.calendarYear, month: this.monthBaseZeroIndex, day: dayOfMonth })
      this.dayOfMonthSelected = dayOfMonth
      this.selectedWeek = null
      this.selectedMonth = null
      this.onClickDay(dateSelected)
    },
    isCurrentDateOnCalendar (monthDay) {
      const calendarDate = new Date(this.calendarYear, this.monthBaseZeroIndex, monthDay)
      return (calendarDate.getTime() === this.currentDate.getTime())
    },
    onDateChange (yearSelected, monthSelected) {
      this.calendarYear = yearSelected
      this.calendarMonth = monthSelected
      this.dayOfMonthSelected = null
      this.selectedWeek = null
      this.onMonthChange()
    }
  }
}
</script>

<style lang="scss">
.he-ui-calendar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  user-select: none;
  border-radius: 0.2rem;
  border-radius: 0.2rem;
}

.he-ui-calendar-container {
  display: flex;
  flex-direction: row;
  height: calc(100% - 116px - 1.5rem);
}

.he-ui-calendar-content {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.he-ui-calendar-content-day-name {
  height: 2rem;
  width: calc(99.9% * (1 / 7)  - .4rem);
  text-transform: uppercase;
  color: $grey-color;
  margin: .2rem;
  font-size: .8rem;
}

.he-ui-calendar-content-empty-day {
  height: 3rem;
  width: calc(99.9% * (1 / 7) - .4rem);
  position: relative;
  background-color: rgba(251, 251, 251, 0.65);
  cursor: auto;
  margin: .2rem;
}

.he-ui-calendar-content-day {
  height: 3rem;
  flex-grow: 0;
  width: calc(99.9% * (1 / 7)  - .4rem);
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .8rem;
  margin: .2rem;
  border-radius: .2rem;

  .he-ui-calendar-content-day-selected {
    width: calc(100% + 0.06rem);
    height: calc(100% + 0.06rem);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &::after {
    content: " ";
    width: calc(100% + 0.06rem);
    height: calc(100% + 0.06rem);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &.he-ui-calendar-content-day-sun {
    background: $grey-light-color;
  }

  &.he-ui-calendar-content-day-sat {
    background: $grey-light-color;
  }

  &.he-ui-calendar-content-day-current {
    background: $blue-color;
  }

  .he-ui-calendar-content-day-number {
    color: $grey-dark-color;
  }
}

.he-ui-calendar-legend {
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  padding: 1rem 1rem 2rem 1rem;

  .he-ui-calendar-legend-ball {
    border-radius: 50%;
    width: 1.3rem;
    height: 1.3rem;
    margin-right: 1rem;
    &.danger {
      background: $red-color;
    }
    &.succes {
      background: $green-color;
    }
  }

  .he-ui-calendar-legend-text {
    font-size: .8rem;
  }
}

</style>
