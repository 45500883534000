<template>
  <div
    class="he-ui-selector-date">
    <div
      class="he-ui-selector-date-prev d-print-none"
      data-test="he-ui-selector-date-prev"
      @click.prevent="onClickPrevDate">
      <span class="material-icons-outlined">chevron_left</span>
    </div>
    <div class="he-ui-selector-date-title">
      <span class="he-ui-selector-date-title-text">
        {{ date }}
      </span>
    </div>
    <div
      class="he-ui-selector-date-next d-print-none"
      data-test="he-ui-selector-date-next"
      @click.prevent="onClickNextDate">
      <span class="material-icons-outlined">chevron_right</span>
    </div>
  </div>
</template>

<script>
import { format, getMonth, getYear } from 'date-fns'

export default {
  props: {
    onDateChange: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      month: getMonth(new Date()) + 1,
      year: getYear(new Date())
    }
  },
  computed: {
    date () {
      const date = new Date(this.year, this.month - 1, 1)
      const formatDate = 'MMMM yyyy'
      return format(date, formatDate)
    }
  },
  methods: {
    onClickPrevDate () {
      this.year = (this.month === 1) ? this.year - 1 : this.year
      this.month = (this.month === 1) ? 12 : this.month - 1
      this.onDateChange(this.year, this.month)
    },
    onClickNextDate () {
      this.year = (this.month === 12) ? this.year + 1 : this.year
      this.month = (this.month === 12) ? 1 : this.month + 1
      this.onDateChange(this.year, this.month)
    }
  }
}
</script>

<style lang="scss">
.he-ui-selector-date {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
  padding: 0.6em;
  border-radius: 0.06rem;

  .he-ui-selector-date-prev, .he-ui-selector-date-next {
    color: $grey-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .he-ui-selector-date-prev {
    margin-right: 0.65rem;
  }

  .he-ui-selector-date-next {
    margin-left: 0.65rem;
  }

  .he-ui-selector-date-title {
    color: #000;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .he-ui-selector-date-title-text {
    position: relative;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 1.2rem;
  }

  .he-ui-selector-date-pdf-icon {
    padding: 0 0.5rem;
    cursor: pointer;
  }
}

</style>
